<template>
   <div>
            <div v-for="(item, index) in items" :key="index" >
                <v-card :elevation="0" class="pages "  >
                    <v-card-text  class="pa-0" >
                       <b>
                       <v-row class="text-center rowfit " > 
                       
                         <v-col cols="12">
                           <span class="fon2mm line-clamp"> <template v-if="item.productCode">[{{item.productCode}}] </template> {{item.name}}</span>
                         </v-col>
                        </v-row>   
                        <v-row class="text-center" > 
                         <v-col cols="12">
                           <barcode v-bind:value="item.barcode" height="20" width="1"   fontSize= "10"  marginTop="3"  marginBottom="1"  />
                         </v-col>
                        </v-row>
                          </b>
                          <table class="footerDateTime">
                            <tr class="fon1mm">
                              <td>
                              <span >Sku : {{item.sku}}</span>
                              </td>
                            </tr>
                              <tr class="fon1mm">
                              <td>
                                  <span >{{DateNow()}}</span>
                              </td>
                            </tr>
                          </table>
                       
                        

                       
                         
                    </v-card-text>
                </v-card>
            </div>
        </div>
</template>

<script>
window.onafterprint = function(e)
{
  close();
};
window.addEventListener('afterprint', (event) => 
{
 close();
});

import VueBarcode from 'vue-barcode';


export default {
components: {
            'barcode': VueBarcode
            },

data: () => ({
  
     items: 
      [{
        sku: 'S000003-3',
        barcode: '885000000033',
        name:"โมเดลรถ CHEVROLET",
        productCode:"C00003",
       
      }],
      item:[],
}),
async created() {

//   let query = decodeURIComponent(window.location.search.substring(1));
// let data = JSON.parse(query);
// console.log(data,'asdsad')

      this.items = JSON.parse(sessionStorage.getItem('selectedStockBody'))

     this.item = await this.$route.query.productMasterItemId
    //  console.log(await decodeURI(this.item))

      print()
    },

methods: {
  DateNow () {
  var today = new Date();
  var date =(today.getDate().toString().length> 1 ? today.getDate().toString() : '0'+today.getDate().toString()) +'/'+((today.getMonth()+1).toString().length> 1 ? (today.getMonth()+1).toString() : '0'+(today.getMonth()+1).toString())+'/'+today.getFullYear();
  var h = today.getHours().toString().length> 1 ? today.getHours().toString() : '0'+today.getHours().toString()
  var m = today.getMinutes().toString().length> 1 ? today.getMinutes().toString() : '0'+today.getMinutes().toString()
  var s = today.getSeconds().toString().length> 1 ? today.getSeconds().toString() : '0'+today.getSeconds().toString()
  var time = h + ':' + m + ':' + s
  var newDatetime = date+' '+time;
  return newDatetime
}
}
}

</script>

<style scoped>
 >>>.vue-barcode-element{
      width: 100%  !important;
    }
  
    .rowfit{
        margin: 4px -12px !important;
        height: 4mm;
    }
    .rowfitIn{
        /* margin: 7px -12px !important; */
        height: 1.5mm;
    }
    .fon2mm{
      font-size: 2.5mm;
      color: #000;
       line-height: 2.4mm;
    }
    .fon1mm{
      font-size: 1.5mm;
      color: #000;
        line-height: 1.5mm;
    }
    .cut-text{
text-decoration: none;
text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */ 
display: block; overflow: hidden; 
white-space: nowrap; 
width: 100%; /* กำหนดความกว้าง */ 
    }
    .line-clamp{
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       overflow: hidden;
    }
  
    .cen{
            display: flex;
    align-items: center;
      }
    .footerDateTime{
            margin-top: -0.25mm;

      }
   @media print {
       .pages {
        size: 40mm 20mm landscape;
        width: 40mm;
        height: 20mm;
        margin: 0;
      page-break-after: always;
      }

    @page {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-left: 0  !important;
        margin-right: 0 !important;
         }
        .rowfit{
        /* margin: -1px -12px !important; */
        /* height: 3mm; */
         }
        .fon2mm{
        font-size: 6pt;
        color: #000;
       }
       .fon1mm{
      font-size: 5pt;
      color: #000;
       }
       .footerDateTime{
            margin-top: -1mm;

      }
       
  
    }
    
    @media screen {
        .pages{
         width: 40mm;
         height: 20mm;
      
      }
    
    }
</style>